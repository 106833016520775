<template>
  <section class="l-content">
    <el-card class="l-condition">
      <el-form :inline="true" :model="condition">
        <el-form-item label="所属企业">
          <l-select v-model="condition.enterpriseCode" :remote="$api.enterpriseList" @change="getPageData"></l-select>
        </el-form-item>
        <el-form-item label="电源类型">
          <el-radio-group size="small" v-model="condition.chargeEnable" @change="getPageData()">
            <el-radio-button label="">全部</el-radio-button>
            <el-radio-button label="0">车载接电</el-radio-button>
            <el-radio-button label="1">USB充电</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" size="small" placeholder="设备编号"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="carId" slot-scope="scope">
          <span v-if="scope.row.carInfo">{{ scope.row.carInfo.carNo || scope.row.carInfo.vin }}</span>
        </template>
        <template slot="chargeEnable" slot-scope="scope">
          <span v-if="scope.row.chargeEnable === 0" class="text-orange">车载接电</span>
          <span v-else-if="scope.row.chargeEnable === 1" class="text-blue">USB充电</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'CarList',
  data() {
    return {
      tableOption: {
        stripe: true,
        height: document.documentElement.clientHeight - this.$heightOffset1 + 'px',
        columns: [
          { label: '序号', type: 'index' },
          { label: '所属企业', prop: 'enterpriseName', overflow: true },
          { label: '设备编号', prop: 'deviceNo', width: 120 },
          { label: '电源类型', slot: 'chargeEnable', width: 120 },
          { label: '责任人', prop: 'reponsibleUserName', width: 150 },
          { label: '绑定车辆', slot: 'carId' },
          { label: 'GPS时间', prop: 'gpsTime' },
          { label: '心跳时间', prop: 'beatTime' },
          { label: '剩余电量', prop: 'electricity', filter: a => (a === null ? '---' : a + '%'), width: 100 },
          { label: '电压', prop: 'voltage', filter: a => (a === null ? '---' : a + 'V'), width: 100 }
        ]
      },
      condition: {
        keyword: '',
        chargeEnable: '',
        enterpriseCode: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null,
      groupList: []
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/platform/gpsdevice/list', [this.condition])
    },
    setReponsibleUser(item) {
      this.$site.sel_enterpriseEmployee(this.userInfo.enterpriseCode).then(user => {
        this.$lgh
          .post('api/enterprise/gpsdevice/updatereponsibleuser', {
            deviceId: item.deviceId,
            reponsibleUserId: user.userId,
            reponsibleUserName: user.name
          })
          .then(e => {
            this.getPageData()
          })
      })
    },
    setDeviceCar(item) {
      this.$site.sel_enterpriseCar(this.userInfo.enterpriseCode).then(data => {
        this.$lgh
          .post('api/enterprise/gpsdevice/updatecar', {
            deviceId: item.deviceId,
            deviceNo: item.deviceNo,
            deviceModel: item.deviceModel,
            lng: item.lng,
            lat: item.lat,
            address: item.address,
            speed: item.speed,
            carId: data.carId
          })
          .then(e => {
            this.getPageData()
          })
      })
    }
  }
}
</script>
